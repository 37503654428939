import React from 'react';
import logo from './lokanta.png';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faTiktok} from '@fortawesome/free-brands-svg-icons';

    function App() {
    return (
    <div className="App">
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo"/>
            <div className="social-media-links">
                <h3>Follow Us On</h3>
                <a href="https://www.facebook.com/lokanta.eu/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: 'white' }}/>
                </a>
                <a href="https://x.com/lokanta_eu" target="_blank" rel="noopener noreferrer"
                   style={{marginLeft: '10px'}}>
                    <FontAwesomeIcon icon={faTwitter} size="2x" style={{ color: 'white' }}/>
                </a>

                <a href="https://www.instagram.com/lokanta.eu/" target="_blank" rel="noopener noreferrer"
                   style={{marginLeft: '10px'}}>
                    <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: 'white' }}/>
                </a>

                <a href="https://www.tiktok.com/@lokanta_eu" target="_blank" rel="noopener noreferrer"
                   style={{marginLeft: '10px'}}>
                    <FontAwesomeIcon icon={faTiktok} size="2x" style={{ color: 'white' }}/>
                </a>
            </div>
        </header>
    </div>
    )
        ;
    }

export default App;